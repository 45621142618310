<template>
  <div>
    <Loader :visible="isLoading" />
    <v-row justify="space-between">
      <div class="t-tway ml-2" v-if="portfolios.length">Mi Portafolio</div>
      <div>
        <v-btn
          rounded
          color="tway-violet"
          class="button-text white--text pa-5 ml-2"
          @click="create"
          v-if="desktop && portfolios.length"
        >
          <v-icon medium class="mr-2"> mdi-plus </v-icon>
          AGREGAR CASO DE ÉXITO
        </v-btn>
      </div>
    </v-row>

    <v-container fluid class="px-0 py-12" v-if="!portfolios.length">
      <v-row :class="desktop ? 'mt-16 mr-8' : ''" :justify="desktop ? 'center' : 'center'" align="center">
        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <v-row justify="center">
            <v-img
              :src="require('@/assets/img/empty-portfolio.svg')"
              class="rounded mb-8"
              contain
              :max-width="desktop ? '50%' : '60%'"
            />
          </v-row>
        </v-col>
      </v-row>
      <v-row
        :class="desktop ? 't-tway text-center' : 'text-subtitle-1 text-center'"
        align="center"
        :justify="desktop ? 'center' : 'center'"
      >
        ¿Aún no registras casos de éxito? <br v-if="mobile" />
        ¡Comienza ahora!
      </v-row>
      <v-row
        :justify="desktop ? 'center' : 'center'"
        :class="desktop ? 'card-text text-center mt-6' : 'empty-state-title-mobile text-center mt-6'"
        align="center"
      >
        Cuéntanos qué proyectos en Transformación ha realizado tu empresa, con quién y cuáles son los aspectos más
        relevantes <br v-if="desktop" />
        que destacarías de esas iniciativas. Esta información servirá para mejorar tu posición en las licitaciones de
        proyectos y
        <br v-if="desktop" />
        Tway se encargará de mostrar a los potenciales clientes tus casos de éxito validados para hacer crecer tus
        posibilidades de
        <br v-if="desktop" />
        hacer un match de alta precisión con ellos.
      </v-row>
      <v-row :class="desktop ? 'mr-8' : ''" :justify="desktop ? 'center' : 'center'">
        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <v-row justify="center">
            <v-btn large rounded color="tway-violet" class="button-text white--text mt-4" @click="create">
              <v-icon medium class="mr-2"> mdi-plus </v-icon>
              AGREGAR CASO DE ÉXITO
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid v-if="portfolios.length">
      <v-row dense>
        <v-col
          v-for="(portfolio, portfolioIndex) in portfolios"
          :key="portfolioIndex"
          cols="12"
          xs="12"
          sm="12"
          md="4"
          lg="4"
          xl="4"
        >
          <v-card>
            <v-card-title>
              <span>{{ portfolio.projectName.substring(0, 20) }}</span>

              <v-spacer />

              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" v-if="!portfolio.clientRate">
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                  <v-btn v-if="portfolio.clientRate" disabled text />
                </template>

                <v-list>
                  <v-list-item @click="(modalUpdate = true), edit(portfolio.id)">
                    <v-list-item-title class="roboto pa-0">
                      <v-icon color="tway-violet" class="mr-3"> mdi-pencil </v-icon>Editar
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="deleteModal(portfolio.id)">
                    <v-list-item-title class="roboto">
                      <v-icon color="tway-violet" class="mr-3"> mdi-delete </v-icon>Eliminar
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-card-title>

            <v-card-actions>
              <v-list class="transparent">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="#7319D5"> mdi-watch </v-icon>
                  </v-list-item-icon>
                  <v-list-item-subtitle v-if="portfolio.startDate && portfolio.finishDate" class="card-text">
                    {{ moment(portfolio.finishDate).diff(portfolio.startDate, "year") }}
                    <span v-if="moment(portfolio.finishDate).diff(portfolio.startDate, 'year') <= 1">Año </span>
                    <span v-else> Años </span>
                    <span v-if="moment(portfolio.finishDate).diff(portfolio.startDate, 'month') % 12 > 0"
                      >y
                      {{ moment(portfolio.finishDate).diff(portfolio.startDate, "month") % 12 }}
                    </span>
                    <span v-if="moment(portfolio.finishDate).diff(portfolio.startDate, 'month') % 12 == 1">mes</span>
                    <span v-if="moment(portfolio.finishDate).diff(portfolio.startDate, 'month') % 12 > 1"> meses</span>
                    <span v-if="moment(portfolio.finishDate).diff(portfolio.startDate, 'month') % 12 == 0" />
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="red" v-else> Sin Registro </v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="#7319D5"> mdi-account-circle </v-icon>
                  </v-list-item-icon>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-subtitle
                        v-if="portfolio.clientBusinessName"
                        class="card-text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ portfolio.clientBusinessName.substring(0, 20) }}
                      </v-list-item-subtitle>
                    </template>
                    <span>{{ portfolio.clientBusinessName }}</span>
                  </v-tooltip>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon class="card-text">
                    <v-icon color="#7319D5"> mdi-circle-double </v-icon>
                  </v-list-item-icon>
                  <v-list-item-subtitle v-if="portfolio.industrialSector" class="card-text">
                    {{ portfolio.industrialSector[0].name }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="red" v-else> Sin Registro </v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon class="card-text">
                    <v-icon color="#7319D5"> mdi-cash </v-icon>
                  </v-list-item-icon>
                  <v-list-item-subtitle v-if="portfolio.billing" class="card-text">
                    {{ portfolio.billing.name }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="red" v-else> Sin Registro </v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title class="card-text"> Evaluación de Cliente </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-rating
                    v-model="portfolio.clientRate"
                    color="yellow darken-3"
                    background-color="grey darken-1"
                    empty-icon="$ratingFull"
                    length="5"
                    hover
                    large
                    readonly
                  />
                </v-list-item>
                <v-list-item>
                  <div v-if="portfolio.clientRate == null" class="border status byc mt-2">PENDIENTE DE EVALUACIÓN</div>
                  <div v-if="portfolio.clientRate > 0" class="status-complete border bgc mt-2">
                    PROYECTO EVALUADO POR EL CLIENTE
                  </div>
                </v-list-item>
              </v-list>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="modalCasoExito" scrollable max-width="733px">
      <v-card>
        <div class="text-modal-cancel text-left ml-5 mb-2 pa-2">Agregar Caso de Éxito</div>
        <v-card-text>
          <v-form ref="form">
            <v-container>
              <v-row class="mt-3">
                <v-text-field
                  v-model="portfolio.projectName"
                  :rules="[required('Nombre del Proyecto')]"
                  label="Nombre del proyecto"
                  placeholder="Escribe el Nombre del proyecto"
                  class="roboto"
                  outlined
                  required
                />
              </v-row>
              <v-row class="mt-3 mx-n6">
                <v-col cols="6" xs="6" sm="6" md="3" lg="3" xl="3">
                  <v-select
                    v-model="portfolio.startMonth"
                    :items="meses"
                    item-value="id"
                    item-text="name"
                    label="Fecha de Inicio"
                    placeholder="mes"
                    outlined
                    class="roboto"
                    @change="errorDate()"
                    :rules="[required('Mes de Inicio')]"
                  />
                </v-col>
                <v-col cols="6" xs="6" sm="6" md="3" lg="3" xl="3">
                  <v-select
                    v-model="portfolio.startYear"
                    :items="years | reverse"
                    item-text="name"
                    label="año"
                    outlined
                    class="roboto"
                    requerid
                    @change="errorDate()"
                    :error-messages="errorMonthStartUpdate()"
                    :rules="[requiredYear('Año de Inicio')]"
                  />
                </v-col>
                <v-col cols="6" xs="6" sm="6" md="3" lg="3" xl="3">
                  <v-select
                    v-model="portfolio.finishMonth"
                    :items="meses"
                    item-value="id"
                    item-text="name"
                    label="Fecha de Término"
                    placeholder="mes"
                    outlined
                    class="roboto"
                    :rules="[required('Mes de Término')]"
                    @change="errorDate()"
                    :error-messages="errorMonthFinish()"
                  />
                </v-col>
                <v-col cols="6" xs="6" sm="6" md="3" lg="3" xl="3">
                  <v-select
                    v-model="portfolio.finishYear"
                    :items="years | reverse"
                    item-text="name"
                    label="año"
                    outlined
                    class="roboto"
                    requerid
                    @change="errorDate()"
                    :rules="[requiredYear('Año de Termino')]"
                    :error-messages="errorYearFinish()"
                  />
                </v-col>
              </v-row>
              <v-row class="mt-3">
                <v-text-field
                  v-model="portfolio.clientTributaryId"
                  label="Rut"
                  placeholder="Escribe el RUT del Cliente"
                  class="roboto"
                  :rules="[required('Rut Empresa'), validateRUT()]"
                  :hint="countryCode === 'CL' ? 'Formato: 12345678-9' : ''"
                  outlined
                  requerid
                  :error-messages="errorCompanyValidator()"
                />
              </v-row>
              <v-row class="mt-3">
                <v-text-field
                  v-model="portfolio.clientBusinessName"
                  label="Razón Social"
                  placeholder="Escribe el Razón Social del Client"
                  class="roboto"
                  :rules="required('Razón Social')"
                  outlined
                  requerid
                  :error-messages="errorCompanyValidator()"
                />
              </v-row>
              <!--
              <v-row class="mt-3">
                <v-text-field
                  v-model="companySii.name"
                  placeholder="Razon Social"
                  class="roboto"
                  disabled
                  outlined
                />
              </v-row>
              -->
              <v-row class="mt-3">
                <v-select
                  v-model="portfolio.industrialSector"
                  :items="availableIndustrialSectors"
                  placeholder="Selecciona el Sector del Proyecto"
                  item-text="name"
                  return-object
                  label="Sector"
                  outlined
                  class="roboto"
                  required
                  :rules="[(v) => !!v || 'Campo Rubro es requerido']"
                />
              </v-row>
              <v-row class="mt-3">
                <v-select
                  v-model="portfolio.billing"
                  :items="billing"
                  item-text="name"
                  return-object
                  placeholder="Facturación del Proyecto"
                  label="Facturación del proyecto"
                  outlined
                  class="roboto"
                  :rules="[(v) => !!v || 'Campo Facturación es requerido']"
                  required
                />
              </v-row>
              <v-row class="mt-3">
                <v-text-field
                  v-model="portfolio.email"
                  label="Correo del Cliente"
                  placeholder="Escribe el correo de contacto"
                  class="roboto"
                  outlined
                  :rules="[required('Correo Corporativo'), validateEmail()]"
                  :error-messages="mailMatchError()"
                  required
                />
              </v-row>
              <v-row class="mt-3">
                <v-textarea
                  v-model="portfolio.plainText"
                  outlined
                  label="Descripción del Proyecto"
                  placeholder="Breve descripción de objetivos medibles y resultados esperados"
                  class="roboto"
                  :rules="[required('Descripción')]"
                  required
                />
              </v-row>
              <v-row>
                <span class="roboto" style="color: #666666; display: flex; align-items: center"
                  >* Una vez agregado el caso de éxito, se le notificará al cliente para que evalúe la experiencia del
                  proyecto.</span
                >
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-center">
            <v-btn
              style="height: 36px; font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
              color="deep-purple accent-4"
              class="roboto text-right roboto mr-6"
              text
              @click="cancelModal()"
            >
              CANCELAR
            </v-btn>
            <v-btn rounded color="tway-violet" class="roboto white--text font-weight-bold" @click="createportfolio">
              AGREGAR CASO
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalUpdate" scrollable max-width="733px">
      <v-card>
        <div class="text-modal-cancel text-left ml-5 mb-2 pa-2">Editar Caso de Éxito</div>
        <v-card-text>
          <v-form v-model="valid" ref="formUpdate">
            <v-container>
              <v-row class="mt-3">
                <v-text-field
                  v-model="portfolioUpdate.projectName"
                  :rules="[required('Nombre del Proyecto')]"
                  label="Nombre del proyecto"
                  placeholder="Escribe el Nombre del proyecto"
                  class="roboto"
                  outlined
                  required
                />
              </v-row>
              <v-row class="mt-3 mx-n6">
                <v-col cols="6" xs="6" sm="6" md="3" lg="3" xl="3">
                  <v-select
                    v-model="portfolioUpdate.startMonth"
                    :items="meses"
                    item-value="id"
                    item-text="name"
                    label="Fecha de Inicio"
                    placeholder="mes"
                    outlined
                    class="roboto"
                    @change="errorDateUpdate()"
                    :rules="[required('Mes de Inicio')]"
                  />
                </v-col>
                <v-col cols="6" xs="6" sm="6" md="3" lg="3" xl="3">
                  <v-select
                    v-model="portfolioUpdate.startYear"
                    :items="years | reverse"
                    item-text="name"
                    label="año"
                    outlined
                    class="roboto"
                    requerid
                    @change="errorDateUpdate()"
                    :error-messages="errorMonthStartUpdate()"
                    :rules="[requiredYear('Año de Inicio')]"
                  />
                </v-col>
                <v-col cols="6" xs="6" sm="6" md="3" lg="3" xl="3">
                  <v-select
                    v-model="portfolioUpdate.finishMonth"
                    :items="meses"
                    item-value="id"
                    item-text="name"
                    label="Fecha de Término"
                    placeholder="mes"
                    outlined
                    class="roboto"
                    @change="errorDateUpdate()"
                    :rules="[required('Mes de Termino')]"
                    :error-messages="errorMonthFinishUpdate()"
                  />
                </v-col>
                <v-col cols="6" xs="6" sm="6" md="3" lg="3" xl="3">
                  <v-select
                    v-model="portfolioUpdate.finishYear"
                    :items="years | reverse"
                    item-text="name"
                    label="año"
                    outlined
                    class="roboto"
                    requerid
                    @change="errorDateUpdate()"
                    :rules="[requiredYear('Año de Termino')]"
                    :error-messages="errorYearUpdateFinish()"
                  />
                </v-col>
              </v-row>
              <v-row class="mt-3">
                <v-text-field
                  v-model="portfolioUpdate.clientTributaryId"
                  label="Razon Social por RUT"
                  placeholder="Escribe el RUT del Cliente"
                  class="roboto"
                  :rules="[required('Rut Empresa'), validateRUT()]"
                  :hint="countryCode === 'CL' ? 'Formato: 12345678-9' : ''"
                  outlined
                  requerid
                  disabled
                />
              </v-row>
              <v-row class="mt-3">
                <v-text-field
                  v-model="portfolioUpdate.clientBusinessName"
                  label="Razón Social"
                  placeholder="Escribe el Razón Social del Client"
                  class="roboto"
                  :rules="required('Razón Social')"
                  outlined
                  requerid
                  disabled
                />
              </v-row>
              <!--
              <v-row class="mt-3">
                <v-text-field
                  v-model="companySii.name"
                  placeholder="Aquí aparecerá el nombre de la Razón Social"
                  class="roboto"
                  disabled
                  outlined
                />
              </v-row>
              -->
              <v-row class="mt-3">
                <v-select
                  v-model="portfolioUpdate.industrialSector"
                  :items="availableIndustrialSectors"
                  placeholder="Selecciona el sector del proyecto"
                  item-text="name"
                  return-object
                  label="Sector"
                  outlined
                  class="roboto"
                  required
                  :rules="[(v) => !!v || 'Campo Rubro es requerido']"
                />
              </v-row>
              <v-row class="mt-3">
                <v-select
                  v-model="portfolioUpdate.billing"
                  :items="billing"
                  item-text="name"
                  return-object
                  placeholder="Selecciona un Rango"
                  label="Facturación del proyecto"
                  outlined
                  class="roboto"
                  :rules="[(v) => !!v || 'Campo Facturación es requerido']"
                  required
                />
              </v-row>
              <v-row class="mt-3">
                <v-text-field
                  v-model="portfolioUpdate.email"
                  label="Correo del Cliente"
                  placeholder="Escribe el correo de contacto"
                  class="roboto"
                  outlined
                  :rules="[required('Correo Corporativo'), validateEmail()]"
                  required
                />
              </v-row>
              <v-row class="mt-3">
                <v-textarea
                  v-model="portfolioUpdate.plainText"
                  outlined
                  label="Descripción del Proyecto"
                  placeholder="Breve descripción de objetivos medibles y resultados esperados"
                  class="roboto"
                  :rules="[required('Descripción')]"
                  requerid
                />
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-center">
            <v-btn
              style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
              color="deep-purple accent-4"
              class="roboto text-right roboto mr-6"
              text
              @click="closeModalUpdate()"
            >
              CANCELAR
            </v-btn>
            <v-btn
              style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
              small
              rounded
              color="tway-violet"
              class="elevation-2 pa-4 white--text roboto"
              @click="updateportfolio(portfolioUpdate.id)"
            >
              EDITAR CASO
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalDelete" max-width="500">
      <v-card :class="desktop ? 'pa-10' : 'p-2'" style="border-radius: 4px !important">
        <v-card-title
          class="headline mb-3"
          :class="!desktop ? 'pt-8' : ''"
          :style="!desktop ? 'font-size: 20px !important;' : ''"
        >
          <v-row class="ma-0 pa-0" justify="center" v-if="!deleteSuccesful">
            <span class="text-center"
              >¿Realmente quieres eliminar<br />
              este caso de éxito?</span
            >
          </v-row>
          <v-row class="ma-0 pa-0" justify="center" v-if="deleteSuccesful">
            <v-icon color="#16C62E" size="95"> mdi-check-circle </v-icon>
          </v-row>
        </v-card-title>

        <v-card-text class="headline tway-gray-dark--text mb-4" style="font-size: 18px !important">
          <v-row class="ma-0 pa-0 text-center" justify="center" v-if="!deleteSuccesful">
            <span>
              Al eliminar un caso de éxito, todos los datos se borrarán permanentemente y no podrás recuperarla.
            </span>
          </v-row>
          <v-row class="ma-0 pa-0 text-center" justify="center" v-if="deleteSuccesful">
            <span> El caso de éxito se ha eliminado definitivamente. </span>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-col v-if="desktop && !deleteSuccesful" class="text-center">
            <a
              style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
              class="tway-violet--text roboto"
              v-if="desktop"
              :class="desktop ? 'mr-8' : 'mt-2 mb-4'"
              @click.stop="modalDelete = false"
            >
              CANCELAR
            </a>
            <v-btn
              style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
              v-if="desktop"
              x-large
              rounded
              color="tway-violet"
              class="elevation-2 pa-6 mb-2 white--text roboto"
              @click="deleteportfolio(deleteId)"
            >
              SI, ELIMINAR
            </v-btn>
          </v-col>
          <v-col v-if="desktop && deleteSuccesful" class="text-center">
            <v-btn
              style="height: 36px; font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
              v-if="desktop"
              x-large
              rounded
              color="tway-violet"
              class="elevation-2 pa-6 mb-2 white--text roboto"
              @click="(modalDelete = false), (deleteSuccesful = false)"
            >
              ACEPTAR
            </v-btn>
          </v-col>
          <v-row v-if="!desktop && !deleteSuccesful" class="ma-0 pa-0" justify="center">
            <v-col cols="12">
              <v-row class="pa-0 ma-0" justify="center">
                <v-btn
                  style="height: 36px; font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
                  x-large
                  rounded
                  color="tway-violet"
                  class="elevation-2 pa-6 white--text roboto"
                  @click="deleteportfolio(deleteId)"
                >
                  SI, ELIMINAR
                </v-btn>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row class="pa-0 ma-0" justify="center">
                <a
                  style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
                  class="tway-violet--text roboto"
                  v-if="!desktop"
                  :class="desktop ? 'mr-8' : 'mt-2 mb-4'"
                  @click.stop="(modalDelete = false), (deleteSuccesful = false)"
                >
                  CANCELAR
                </a>
              </v-row>
            </v-col>
          </v-row>
          <v-col v-if="mobile && deleteSuccesful" class="text-center">
            <v-btn
              style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
              v-if="mobile"
              x-large
              rounded
              color="tway-violet"
              class="elevation-2 pa-6 mb-2 white--text roboto"
              @click="modalDelete = false"
            >
              ACEPTAR
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalCancel" max-width="500">
      <v-card :class="desktop ? 'pa-10' : 'p-2'" style="border-radius: 4px !important">
        <v-card-title
          class="headline mb-3"
          :class="!desktop ? 'pt-8' : ''"
          :style="!desktop ? 'font-size: 20px !important;' : ''"
        >
          <v-row class="ma-0 pa-4" justify="center">
            <span class="text-modal-cancel text-center">¿Quieres salir sin agregar tu caso de éxito?</span>
          </v-row>
        </v-card-title>

        <v-card-text class="headline tway-gray-dark--text mb-4" style="font-size: 18px !important">
          <v-row class="ma-0 pa-0" justify="center">
            <span class="subtitle-1 text-center" style="line-height: 20px; text-align: center">
              Si sales se perderá el progreso que llevas registrado.
            </span>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-col v-if="desktop" class="text-center">
            <a
              style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
              class="tway-violet--text cancel-button"
              v-if="desktop"
              :class="desktop ? 'mr-8' : 'mt-2 mb-4'"
              @click.stop="modalCancel = false"
            >
              CANCELAR
            </a>
            <v-btn
              style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
              v-if="desktop"
              large
              rounded
              color="tway-violet cancel-button"
              class="elevation-2 pa-6 white--text"
              @click="closeModal(), (modalCancel = false)"
            >
              salir sin agregar caso
            </v-btn>
          </v-col>
          <v-row v-if="!desktop" class="ma-0 pa-0" justify="center">
            <v-col cols="12">
              <v-row class="pa-0 ma-0" justify="center">
                <v-btn
                  style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
                  large
                  rounded
                  color="tway-violet"
                  class="elevation-2 pa-6 white--text roboto"
                  @click="closeModal(), (modalCancel = false)"
                >
                  salir sin agregar caso
                </v-btn>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row class="pa-0 ma-0" justify="center">
                <a
                  style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
                  class="tway-violet--text roboto"
                  v-if="!desktop"
                  :class="desktop ? 'mr-8' : 'mt-2 mb-4'"
                  @click.stop="modalCancel = false"
                >
                  CANCELAR
                </a>
              </v-row>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row justify="center" v-if="mobile && portfolios.length">
      <v-btn rounded color="tway-violet" class="button-text white--text pa-5 roboto ml-2" @click="create">
        <v-icon medium class="mr-2"> mdi-plus </v-icon>
        AGREGAR CASO DE ÉXITO
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import portfolioService from "@/services/portfolioService";
import billingJSON from "@/utils/billing.json";
import companyService from "@/services/companyService";
import ValidatorsService from "@/services/validatorsService";
import CurrentUserService from "@/services/currentUserService";
import Loader from "@/components/Loader";
import moment from "moment";

export default {
  name: "Myportfolio",

  components: {
    Loader,
  },
  data: () => ({
    valid: true,
    mode: "",
    titlemodal: "",
    moment: moment,
    isLoading: true,
    user: {
      firstName: "",
      lastName: "",
      username: "",
    },
    portfolios: [],
    portfolioUpdate: {
      specialistId: "",
      projectName: "",
      startDay: "01",
      startMonth: "",
      startYear: "",
      finishDay: "01",
      finishMonth: "",
      finishYear: "",
      clientTributaryId: "",
      clientBusinessName: "",
      industrialSector: null,
      billing: null,
      email: "",
      plainText: "",
    },
    timeProject: "",
    companySii: {
      id: "",
      name: "",
    },
    industrialSector: [],
    portfolio: {
      specialistId: "",
      projectName: "",
      startDay: "01",
      startMonth: "",
      startYear: "",
      finishDay: "01",
      finishMonth: "",
      finishYear: "",
      clientTributaryId: "",
      clientBusinessName: "",
      industrialSector: null,
      billing: null,
      email: "",
      plainText: "",
    },
    billing: [],
    availableIndustrialSectors: [],
    modalCasoExito: false,
    modalUpdate: false,
    modalDelete: false,
    modalCancel: false,
    deleteId: "",
    deleteSuccesful: false,
    countryCode: "CL",
    form: {
      rut: "",
    },
    periodo: "",
    meses: [
      { id: "01", name: "Enero" },
      { id: "02", name: "Febrero" },
      { id: "03", name: "Marzo" },
      { id: "04", name: "Abril" },
      { id: "05", name: "Mayo" },
      { id: "06", name: "Junio" },
      { id: "07", name: "Julio" },
      { id: "08", name: "Agosto" },
      { id: "09", name: "Septiembre" },
      { id: "10", name: "Octubre" },
      { id: "11", name: "Noviembre" },
      { id: "12", name: "Diciembre" },
    ],
    yearFinishUpdateWrong: true,
    monthStartUpdateWrong: true,
    monthDateFinishUpdateWrong: true,
    alertyearLimit: "",
    alertdateLimit: "",

    yearFinishWrong: true,
    monthStartWrong: true,
    monthDateFinishWrong: true,
    alertyear: "",
    alertdate: "",

    companyValidator: false,
    company: {
      tributaryId: "",
    },
  }),

  created() {
    this.getportfolios();
    this.getBilling();
    this.getCurrenUserData();
    this.getProfile();

    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let clientId = "";
    if (userInfo && userInfo.principal && userInfo.principal.clientId) {
      clientId = userInfo.principal.clientId;
    }
    if (clientId) {
      companyService
        .getSectors(clientId)
        .then((response) => {
          this.availableIndustrialSectors = response.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
        })
        .catch((err) => this.$log.error(err));
    }
  },

  methods: {
    required: (propertyType) => ValidatorsService.required(propertyType),
    requiredYear: (propertyType) => ValidatorsService.requiredYear(propertyType),
    validateEmail: () => ValidatorsService.validateEmail(),
    errorDate: function () {
      //metodo que valida que la fecha de termino no sea mayor a la fecha de inicio
      const year = new Date().getFullYear();
      const mes = new Date().getMonth();
      let indexStartMonth = this.meses.findIndex((element) => {
        return element.id == this.portfolio.startMonth;
      });
      let indexFinishMonth = this.meses.findIndex((element) => {
        return element.id == this.portfolio.finishMonth;
      });
      if (
        this.portfolio.finishYear &&
        this.portfolio.startYear &&
        this.portfolio.finishYear < this.portfolio.startYear
      ) {
        this.alertdate = "el año final es mayor que el inicial";
        this.yearFinishWrong = false;
      } else if (this.portfolio.finishYear && this.portfolio.startYear) {
        if (
          indexStartMonth > -1 &&
          indexFinishMonth > -1 &&
          indexFinishMonth < indexStartMonth &&
          this.portfolio.finishYear <= this.portfolio.startYear
        ) {
          this.alertdate = "el mes final es mayor que el inicial";
          this.yearFinishWrong = false;
        } else {
          this.yearFinishWrong = true;
        }
      }
      if (this.portfolio.finishYear == year || this.portfolio.startYear == year) {
        if (indexStartMonth > mes) {
          this.monthStartWrong = false;
          this.alertyear = "mes de inicio excede la fecha actual";
        } else {
          this.monthStartWrong = true;
        }
        if (indexFinishMonth > mes) {
          this.monthDateFinishWrong = false;
          this.alertyear = "mes de termino excede la fecha actual";
        } else {
          this.monthDateFinishWrong = true;
        }
      }
    },
    errorDateUpdate: function () {
      //metodo que valida que la fecha de termino no sea mayor a la fecha de inicio
      const year = new Date().getFullYear();
      const mes = new Date().getMonth();
      let indexStartMonth = this.meses.findIndex((element) => {
        return element.id == this.portfolioUpdate.startMonth;
      });
      let indexFinishMonth = this.meses.findIndex((element) => {
        return element.id == this.portfolioUpdate.finishMonth;
      });
      if (this.portfolioUpdate.finishYear < this.portfolioUpdate.startYear) {
        this.alertdateLimit = "el año final es mayor que el inicial";
        this.yearFinishUpdateWrong = false;
      } else if (
        indexFinishMonth < indexStartMonth &&
        this.portfolioUpdate.finishYear <= this.portfolioUpdate.startYear
      ) {
        this.alertdateLimit = "el mes final es mayor que el inicial";
        this.yearFinishUpdateWrong = false;
      } else {
        this.yearFinishUpdateWrong = true;
      }

      if (this.portfolioUpdate.finishYear == year || this.portfolioUpdate.startYear == year) {
        if (indexStartMonth > mes && this.portfolioUpdate.startYear > year) {
          this.monthStartUpdateWrong = false;
          this.alertyearLimit = "mes de inicio excede la fecha actual";
        } else {
          this.monthStartUpdateWrong = true;
        }
        if (indexFinishMonth > mes) {
          this.monthDateFinishUpdateWrong = false;
          this.alertyearLimit = "mes de termino excede la fecha actual";
        } else {
          this.monthDateFinishUpdateWrong = true;
        }
      }
    },
    errorYearUpdateFinish() {
      return this.yearFinishUpdateWrong ? "" : this.alertdateLimit;
    },
    errorMonthStartUpdate() {
      return this.monthStartUpdateWrong ? "" : this.alertyearLimit;
    },
    errorMonthFinishUpdate() {
      return this.monthDateFinishUpdateWrong ? "" : this.alertyearLimit;
    },
    errorYearFinish() {
      return this.yearFinishWrong ? "" : this.alertdate;
    },
    errorMonthStart() {
      return this.monthStartWrong ? "" : this.alertyear;
    },
    errorMonthFinish() {
      return this.monthDateFinishWrong ? "" : this.alertyear;
    },
    errorCompanyValidator() {
      if (this.portfolio.clientTributaryId === this.company.tributaryId) {
        return "Rut de Cliente no puede ser el Rut asociado a su cuenta";
      }
      if (this.companyValidator) {
        return "Rut de Cliente no valido";
      }
    },
    mailMatchError: function () {
      return this.portfolio.email === this.user.username
        ? "Correo de Cliente no puede ser el Correo asociado a su cuenta"
        : "";
    },
    validateRUT() {
      if (this.countryCode === "CL") {
        return ValidatorsService.validateRUT();
      }
      return true;
    },
    create() {
      this.titlemodal = "Agregar Caso";
      this.modalCasoExito = true;
    },
    edit(id) {
      this.getportfolio(id);
      this.modalUpdate = true;
    },
    deleteModal(id) {
      this.deleteId = id;
      this.modalDelete = true;
    },
    cancelModal() {
      if (
        !this.portfolio.billing &&
        !this.portfolio.email &&
        !this.portfolio.finishMonth &&
        !this.portfolio.finishYear &&
        !this.portfolio.industrialSector &&
        !this.portfolio.plainText &&
        !this.portfolio.projectName &&
        !this.portfolio.startMonth &&
        !this.portfolio.startYear &&
        !this.portfolio.clientTributaryId
      ) {
        this.modalCasoExito = false;
      } else {
        this.modalCancel = true;
      }
    },
    closeModal() {
      this.modalCasoExito = false;
      this.resetForm();
    },
    closeModalUpdate() {
      this.modalUpdate = false;
      this.resetFormUpdate();
      //this.form.rut = null;
    },
    resetForm() {
      this.$refs.form.reset();
      //this.form.rut = null;
    },
    resetFormUpdate() {
      this.$refs.formUpdate.reset();
    },
    getBilling() {
      this.billing = billingJSON;
    },
    getProfile() {
      companyService
        .getCompanyProfile()
        .then((company) => (this.company = company))
        .catch((err) => this.$log.error(err));
    },
    getCurrenUserData() {
      CurrentUserService.currentUserData()
        .then((userData) => (this.user = userData))
        .catch((err) => this.$log.error(err));
    },
    getportfolios: function () {
      portfolioService
        .getportfolios()
        .then((portfolios) => (this.portfolios = portfolios))
        .catch((err) => {
          if (err.response.status === 409 || err.response.status === 401) {
            this.$router.push({ name: "plans" });
          }
          this.$log.error("Unexpected error: ", err);
        })
        .then(() => (this.isLoading = false));
    },
    deleteportfolio: function (deleteId) {
      portfolioService
        .deleteportfolio(deleteId)
        .then((this.portfolios = this.portfolios.filter((e) => e.id != deleteId)), (this.deleteSuccesful = true))
        .catch((err) => this.$log.error("Unexpected error: ", err));
    },
    createportfolio: function () {
      if (
        this.$refs.form.validate() &&
        this.yearFinishWrong &&
        this.monthStartWrong &&
        this.monthDateFinishWrong &&
        !this.companyValidator
      ) {
        let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        let clientId = "";
        if (userInfo && userInfo.principal && userInfo.principal.clientId) {
          clientId = userInfo.principal.clientId;
        }
        let data = {
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          specialistId: clientId,
          clientTributaryId: this.portfolio.clientTributaryId,
          clientBusinessName: this.portfolio.clientBusinessName,
          projectName: this.portfolio.projectName,
          startDate: this.portfolio.startYear + "-" + this.portfolio.startMonth + "-" + this.portfolio.startDay,
          finishDate: this.portfolio.finishYear + "-" + this.portfolio.finishMonth + "-" + this.portfolio.finishDay,
          industrialSector: [
            {
              id: this.portfolio.industrialSector.id,
              name: this.portfolio.industrialSector.name,
            },
          ],
          billing: this.portfolio.billing,
          email: this.portfolio.email,
          plainText: this.portfolio.plainText,
        };
        portfolioService
          .createportfolio(data)
          .then((portfolio) => this.portfolios.push(portfolio), this.resetForm())
          .catch((err) => {
            this.$log.error("Unexpected error: ", err);
          })
          .finally((this.modalCasoExito = false));
      }
    },
    updateportfolio: function (id) {
      if (
        this.$refs.formUpdate.validate() &&
        this.yearFinishUpdateWrong &&
        this.monthStartUpdateWrong &&
        this.monthDateFinishUpdateWrong
      ) {
        this.isLoading = true;
        let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        let clientId = "";
        if (userInfo && userInfo.principal && userInfo.principal.clientId) {
          clientId = userInfo.principal.clientId;
        }
        let data = {
          specialistId: clientId,
          projectName: this.portfolioUpdate.projectName,
          startDate: this.portfolioUpdate.startYear + "-" + this.portfolioUpdate.startMonth + "-" + "01",
          finishDate: this.portfolioUpdate.finishYear + "-" + this.portfolioUpdate.finishMonth + "-" + "01",
          industrialSector: [this.portfolioUpdate.industrialSector],
          billing: this.portfolioUpdate.billing,
          email: this.portfolioUpdate.email,
          plainText: this.portfolioUpdate.plainText,
        };
        portfolioService
          .updateportfolio(data, id)
          .then(this.getportfolios())
          .catch((err) => this.$log.error("Unexpected error: ", err))
          .finally((this.modalUpdate = false), this.getportfolios(), (this.isLoading = false));
      }
    },
    getportfolio: function (id) {
      portfolioService
        .getportfolio(id)
        .then((item) => {
          this.portfolioUpdate = item;
          //this.portfolioUpdate.industrialSector = null;
          this.portfolioUpdate.startMonth = moment(item.startDate).format("MM");
          this.portfolioUpdate.startYear = parseInt(moment(item.startDate).format("yyyy"));
          this.portfolioUpdate.finishMonth = moment(item.finishDate).format("MM");
          this.portfolioUpdate.finishYear = parseInt(moment(item.finishDate).format("yyyy"));
          if (item.industrialSector.length > 0) {
            this.portfolioUpdate.industrialSector = item.industrialSector[0];
          } else {
            item.industrialSector = null;
          }
          this.portfolioUpdate.clientTributaryId = item.clientTributaryId;
          this.portfolioUpdate.clientBusinessName = item.clientBusinessName;
        })
        .catch((err) => this.$log.error("Unexpected error: ", err));
    },
  },

  /*
  watch: {
    "form.rut"() {
      if (this.countryCode === "CL" && ValidatorsService.rut(this.form.rut)) {
        this.companyValidator = true;
        this.companySii = {
          id: "",
          name: "Nombre no Encontrado",
        };
      }
    },
  },
  */

  computed: {
    years() {
      const year = new Date().getFullYear();
      return Array.from({ length: year - 1980 }, (value, index) => 1981 + index);
    },
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>
<style lang="scss" scope>
.status {
  position: static;
  left: 4.76%;
  right: 4.76%;
  top: 20%;
  bottom: 20%;

  /* Buttons / Extra Small Button */

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* yellow / yellow darken-4 */

  color: #f57f17;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  align-self: center;
  flex-grow: 0;
  margin: 10px 0px;
}
.status-complete {
  position: static;
  left: 4.76%;
  right: 4.76%;
  top: 20%;
  bottom: 20%;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  /* yellow / yellow darken-4 */

  color: #16c62e;

  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  align-self: center;
  flex-grow: 0;
  margin: 10px 0px;
}

.border {
  /* Primary / Extra Small Button */

  /* Auto Layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 3px 3px 8px;

  position: static;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  align-self: flex-start;
  flex-grow: 0;
  margin: 0px 0px;
}

.bgc {
  /* green / green lighten-5 */

  background: #e8f5e9;
  border-radius: 4px;
}
.byc {
  /* orange / orange lighten-5 */

  background: #fff3e0;
  border-radius: 4px;
}

.card-text {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px !important;
  /* identical to box height, or 150% */
  color: #212121 !important;
}
.red {
  color: #b71c1c;
}
.t-tway {
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 32px;
}
.border.v-input .v-input__slot {
  border: 1px solid #7319d5;
  box-sizing: border-box;
  border-radius: 4px;
}

.empty-state-title-mobile {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #212121;
}

.v-text-field--outlined fieldset {
  border-color: #7319d5;
}

.text-modal-cancel {
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  /* or 136% */

  text-align: center;
}

.cancel-button {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;

  align-items: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}
</style>
