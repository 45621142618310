import Vue from "vue";
import uaaAxios from "@/utils/authAxios";

export default {
  getportfolios: function () {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let clientId = "";
    if (userInfo && userInfo.principal && userInfo.principal.clientId) {
      clientId = userInfo.principal.clientId;
    }
    return new Promise((resolve, reject) => {
      uaaAxios
        .get(`${process.env.VUE_APP_COMPANIES_API}/portfolios/${clientId}/resumes`)
        .then((response) => {
          resolve(Vue._.get(response, "data"));
        })
        .catch((err) => reject(err));
    });
  },
  deleteportfolio: function (id) {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let clientId = "";
    if (userInfo && userInfo.principal && userInfo.principal.clientId) {
      clientId = userInfo.principal.clientId;
    }
    return new Promise((resolve, reject) => {
      uaaAxios
        .delete(`${process.env.VUE_APP_COMPANIES_API}/portfolios/${clientId}/resumes?portfolioId=${id}`)
        .then((response) => {
          resolve(Vue._.get(response, "data"));
        })
        .catch((err) => reject(err));
    });
  },
  createportfolio: function (data) {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let clientId = "";
    if (userInfo && userInfo.principal && userInfo.principal.clientId) {
      clientId = userInfo.principal.clientId;
    }
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${process.env.VUE_APP_COMPANIES_API}/portfolios/${clientId}/resumes`, data)
        .then((response) => {
          resolve(Vue._.get(response, "data"));
        })
        .catch((err) => reject(err));
    });
  },
  updateportfolio: function (data, id) {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let clientId = "";
    if (userInfo && userInfo.principal && userInfo.principal.clientId) {
      clientId = userInfo.principal.clientId;
    }
    return new Promise((resolve, reject) => {
      uaaAxios
        .put(`${process.env.VUE_APP_COMPANIES_API}/portfolios/${clientId}/resumes?portfolioId=${id}`, data)
        .then((response) => {
          resolve(Vue._.get(response, "data"));
        })
        .catch((err) => reject(err));
    });
  },
  getportfolio: function (id) {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let clientId = "";
    if (userInfo && userInfo.principal && userInfo.principal.clientId) {
      clientId = userInfo.principal.clientId;
    }
    return new Promise((resolve, reject) => {
      uaaAxios
        .get(`${process.env.VUE_APP_COMPANIES_API}/portfolios/${clientId}/resumes/${id}`)
        .then((response) => {
          resolve(Vue._.get(response, "data"));
        })
        .catch((err) => reject(err));
    });
  },
};
